import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from "gatsby-plugin-image"

import LayoutLanding from "../components/layout-landing"
import Seo from "../components/seo"
import HeaderLanding from "../components/header/header-landing"
import HeroSection831bFourSimpleNew from "../components/sections/hero-section-831b-4-simple-new"
import Section831bFourIntro from "../components/sections/section-831b-4-intro"
import Section831bFourBenefitsNew from "../components/sections/section-831b-4-benefits-new"
import Section831bFourPartTestUpdate from "../components/sections/section-831b-4-part-test-update"
import Section831bFourTestimonialsNew from "../components/sections/section-831b-4-testimonials-new"
import Section831bFourWhoCanBenefit from "../components/sections/section-831b-4-who-can-benefit"
import Section831bFourPressNews from "../components/sections/section-831b-4-press-news"

const Page831bPlanLandingOne = () => {
  const data = useStaticQuery(graphql`
    query Page831bPlanLandingOneQuery {
      imgHero: file(relativePath: { eq: "backgrounds/mission-section-bg-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgSrc = getSrc(data.imgHero)

  return (
    <LayoutLanding>
      <Seo
        title="SRA 831(b) Plan"
        description="Much like the 401(k) tax code allows an employer to set aside tax-deferred dollars for retirement, the 831(b) tax code allows a business to set aside tax-deferred dollars for underinsured and/or uninsured risks. The similarities are clear and business owners should consider the risk mitigation advantages."
        image={imgSrc}
        noIndex
      />

      <HeaderLanding />

      <HeroSection831bFourSimpleNew 
        title="Tax deferral today to address tomorrow’s risks."
        subTitle="SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies."
      />

      <Section831bFourIntro />

      <Section831bFourBenefitsNew />

      <Section831bFourWhoCanBenefit />

      <Section831bFourPartTestUpdate />

      <Section831bFourTestimonialsNew />

      <Section831bFourPressNews />
    </LayoutLanding>
  )
}

export default Page831bPlanLandingOne
